/**
 * Created by vladislav on 30.09.2020
 */

var CurrentHighlighterView = cc.Node.extend({
    ctor: function (highlighter, boardView) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.highlighter = highlighter;
        this.grid = boardView.grid;
        this.boardView = boardView;

        this.frame = new cc.Scale9Sprite(bundles.game.frames.board_highlight_frame);
        this.addChild(this.frame);

        this.setOpacity(0);

        this.setCascadeOpacityEnabled(true);

        this.runningAnimations = [];

        this.highlighter.on("changeCurrent", this.createListener(this.changeCurrent.bind(this)));
    },

    changeCurrent: function () {
        cleverapps.substract(this.runningAnimations, this.highlighter.current).forEach(function (index) {
            this.stopAnimation(index);
        }.bind(this));

        cleverapps.substract(this.highlighter.current, this.runningAnimations).forEach(function (index) {
            this.runAnimation(index);
        }.bind(this));

        this.runningAnimations = this.highlighter.current;
    },

    runAnimation: function (index) {
        var styles = cleverapps.styles.CurrentHighlighterView;

        var scene = cleverapps.scenes.getRunningScene();

        var start = this.grid.getCell(index, 0).getPosition();
        start.y += styles.flashlight.offsetY;
        start.x += styles.flashlight.offsetX;
        var finish = this.grid.getCell(index, this.grid.getColumn(index).length - 1).getPosition();
        finish.y += styles.flashlight.offsetY;
        finish.x += styles.flashlight.offsetX;

        start = scene.convertToNodeSpace(this.boardView.grid.convertToWorldSpace(start));
        finish = scene.convertToNodeSpace(this.boardView.grid.convertToWorldSpace(finish));

        var flashlight = new cleverapps.Spine(bundles.game.jsons.flashlight_json);
        scene.addChild(flashlight);
        flashlight.setLocalZOrder(10);
        flashlight.setScale(this.boardView.grid.scale);
        flashlight.setPositionRound(start);
        flashlight.setAnimationAndIdleAfter("show", "idle");

        var time = this.grid.getColumn(index).length * 0.2;
        flashlight.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.MoveTo(time, finish),
            new cc.CallFunc(function () {
                flashlight.setAnimation(0, "hide", false);
                flashlight.setCompleteListenerRemove();
            })
        ));

        var actions = this.grid.getColumn(index).map(function (placeholder, index) {
            return new cc.Sequence(
                new cc.DelayTime(0.3 + index * 0.2),
                new cc.CallFunc(function () {
                    var animation = placeholder.highlightAnimation = new cleverapps.Spine(bundles.game.jsons.highlight_booster_json);
                    animation.setCascadeOpacityEnabled(false);
                    animation.setPositionRound(placeholder.width / 2, placeholder.height / 2);
                    animation.setLocalZOrder(-1);
                    animation.setAnimation(0, "show", false);
                    placeholder.addChild(animation);
                })
            );
        });

        var action = new cc.Spawn(actions);
        action.setTag(index);

        this.runAction(action);

        cleverapps.audio.playSound(bundles.game.urls.highlight_booster_effect);
    },

    stopAnimation: function (index) {
        this.stopActionByTag(index);

        this.grid.getColumn(index).forEach(function (placeholder) {
            if (placeholder.highlightAnimation) {
                placeholder.highlightAnimation.setAnimation(0, "hide", false);
                delete placeholder.highlightAnimation;
            }
        });
    }
});

cleverapps.styles.CurrentHighlighterView = {
    flashlight: {
        offsetY: 50,
        offsetX: 50
    },
    padding: {
        x: 10,
        y: 10
    },
    animation: {
        offsetY: 7
    }
};