/**
 * Created by vladislav on 30.11.2020
 */

var Magnifier = function (game) {
    cleverapps.EventEmitter.call(this);

    this.reward = new Reward("hard", Magnifier.GOLD, {
        event: cleverapps.EVENTS.EARN.MAGNIFIER
    });

    this.game = game;
};

Magnifier.prototype = Object.create(cleverapps.EventEmitter.prototype);
Magnifier.prototype.constructor = Magnifier;

Magnifier.prototype.showUp = function (silent) {
    this.trigger("showUp", silent);
};

Magnifier.prototype.getReward = function () {
    return this.reward;
};

Magnifier.prototype.beforeUse = function () {
    this.trigger("beforeUse");
};

Magnifier.prototype.move = function () {
    this.ready = true;

    this.trigger("move");
};

Magnifier.prototype.use = function (f) {
    var hint = this.game.hintBooster.findHint();

    if (hint) {
        this.addHint(hint, f);
    } else {
        this.addGold(f);
    }

    this.trigger("use", hint);
};

Magnifier.prototype.addHint = function (hint, f) {
    this.game.counter.setTimeout(function () {
        this.game.hintBooster.addHint(hint, {
            startPos: MagnifierView.getCollectPosition(),
            hintAnimationDuration: 0.3
        });
        f && f();
    }.bind(this), this.ready ? 400 : 800);
};

Magnifier.prototype.addGold = function (f) {
    this.game.counter.setTimeout(function () {
        this.reward.receiveReward();
        this.game.counter.setTimeout(function () {
            f && f();
        }, 200);
    }.bind(this), this.ready ? 800 : 1600);
};

Magnifier.GOLD = 3;