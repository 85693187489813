/**
 * Created by vladislav on 28.07.20.
 */

var HintView = cc.Node.extend({
    ctor: function (hint) {
        this._super();

        this.hint = hint;

        this.setAnchorPoint(0.5, 0.5);

        cleverapps.tooltipManager.create(this, {
            text: "LevelTaskTooltip",
            location: cleverapps.UI.Tooltip.LOCATION_BELOW,
            control: "hintViewTooltip"
        });
        var text = this.text = cleverapps.UI.generateTTFText(this.hint, cleverapps.styles.FONTS.HINT_TEXT);
        this.addChild(text);

        var img = HintView.themeBg ? HintView.themeBg : bundles.game.frames.theme_bg_regular_blue;
        var bg = this.bg = cleverapps.UI.createScale9Sprite(img, cleverapps.UI.Scale9Rect.TwoPixelXY);

        this.visible = false;
        this.addChild(bg);
        bg.setLocalZOrder(-1);
        this.updateSize();
        this.setupChildren();

        cleverapps.UI.wrap(this);
    },

    updateSize: function () {
        var styles = cleverapps.styles.HintView;
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            this.text.setFontSize(styles.text.verticalFontSize);
        } else {
            this.text.setFontSize(styles.text.horizontalFontSize);
        }

        this.bg.setContentSize(this.text.width + styles.bg.padding.x, this.text.height + styles.bg.padding.y);
    },

    setupChildren: function () {
        var styles = cleverapps.styles.HintView;

        if (this.showUpAction && !this.showUpAction.isDone()) {
            this.stopAction(this.showUpAction);
            delete this.showUpAction;
            this.setScale(1);
        }

        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound(cleverapps.resolution.getSceneSize().width / 2, styles.y);
        this.text.setPositionRound(this.width / 2, this.height / 2 + styles.text.dy);
        this.bg.setPositionRound(this.width / 2, this.height / 2);
    },

    showUp: function (callback, silent) {
        if (silent) {
            this.setVisible(true);
            callback();
        } else {
            var styles = cleverapps.styles.HintView;
            var pos = this.getPosition();
            this.setVisible(true);
            this.setOpacity(0);
            this.setScale(0.5);
            this.setPositionRound(styles.startPos);
            this.runAction(new cc.Sequence(
                new cc.DelayTime(0.9),
                new cc.CallFunc(callback)
            ));
            this.showUpAction = this.runAction(new cc.Sequence(
                new cc.PlaySound(bundles.game.urls.subject_effect),
                new cc.Spawn(
                    new cc.FadeIn(0.4),
                    new cc.ScaleTo(0.4, 1.5)
                ),
                new cc.DelayTime(0.5),
                new cc.DelayTime(0.25),
                new cc.Spawn(
                    new cc.MoveTo(0.55, pos),
                    new cc.ScaleTo(0.55, 1)
                )
            ));
        }
    },

    hideAnimation: function () {
        this.setCascadeOpacityEnabledRecursively(true);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.FadeOut(0.5),
            new cc.Hide()
        ));
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HINT_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.HintView = {
    y: { align: "top", anchor: false, dy: -130 },

    startPos: {
        x: { align: "center" },
        y: { align: "center", dy: 150 }
    },

    bg: {
        padding: {
            x: 50,
            y: 0
        }
    },

    text: {
        dy: 1,
        verticalFontSize: 30,
        horizontalFontSize: 45
    }
};