/**
 * Created by spepa on 18.12.2020
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    GREEN: new cc.Color(13, 119, 0, 255)
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {

    BOOSTER_BEFORE_AMOUNT_TEXT: {
        name: "default",
        size: 40
    }
});

cleverapps.overrideStyles(cleverapps.styles.CurrentView, {
    bg: {
        height: 70,
        padding: {
            x: 30
        },
        resize: true,
        y: { align: "center" }
    },

    regularText: {
        position: {
            x: 0, y: 5
        }
    },

    lettersHolder: {
        y: -5
    },

    statusIcon: {
        offsetX: 60
    },

    position: [
        { x: { align: "center" }, y: { align: "center", dy: 230 } },
        { x: { align: "center" }, y: { align: "center", dy: 230 } },
        { x: { align: "center" }, y: { align: "center", dy: 230 } }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.CurrentLetterView, {
    noBg: true
});

cleverapps.overrideStyles(cleverapps.styles.BoardView, {
    placeHolder: {
        openBg: undefined
    }
});
